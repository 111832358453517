import CategoryProductListPreSliderImage from "./CategoryProductListPreSliderImage";
import {ProductCard} from "../product-card/ProductCard";
import {ProductCore} from "../../lib/model/product/ProductModel";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";

export interface CategoryProductListRowsProps {
	products: ProductCore[] | undefined
	amountOfProductsToShow: number
	preSlider: string
	blackFridayActive: boolean
	orderId?: number
}

export default function CategoryProductListRows(props: CategoryProductListRowsProps) {

	const isMobile = !useMediaQuery(mediaQueries.sm);

	return(
		<div className={`${isMobile ? 'tw-flex tw-flex-col' : 'tw-grid tw-grid-cols-4 tw-gap-10 tw-mt-4 tw-mb-12'}`}>
			{!isMobile && <CategoryProductListPreSliderImage
				preSlider={props.preSlider} /> }
			{props.products?.slice(0, props.amountOfProductsToShow).map(product => (
				<ProductCard
					blackFridayActive={props.blackFridayActive}
					orderId={props.orderId}
					key={product.productId}
					mode={"responsive"}
					product={product} />
			))}
		</div>
	);

}