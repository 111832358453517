import {useEffect, useState} from "react";
import {ProductCore} from "../../lib/model/product/ProductModel";
import {getCategoryWithProducts} from "../../lib/api/productApi";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import CategoryProductListLoadMoreButton from "./CategoryProductListLoadMoreButton";
import CategoryProductListHeader from "./CategoryProductListHeader";
import CategoryProductListRows from "./CategoryProductListRows";

export interface CategoryProductListProps {
	categoryId: string
	initialLines: number
	blackFridayActive: boolean
	orderId?: number
}

export function CategoryProductList(props: CategoryProductListProps) {

	const [products, setProducts] = useState<ProductCore[]>();
	const [preSlider, setPreSlider] = useState<string>();
	const [link, setLink] = useState<string>();
	const [title, setTitle] = useState<string>();
	const [amountOfProductsToShow, setAmountOfProductsToShow] = useState<number>(0);

	const isMobile = !useMediaQuery(mediaQueries.sm);

	useEffect(() => {
		getCategoryWithProducts(props.categoryId)
			.then(categoryWithProducts => {
				setProducts(categoryWithProducts.products);
				let ps = isMobile ? categoryWithProducts.preSliderMobile : categoryWithProducts.preSliderDesktop;
				setPreSlider(ps);
				setLink(categoryWithProducts.link);
				setTitle(categoryWithProducts.title);
				setAmountOfProductsToShow(props.initialLines * 4 - (ps ? 1 : 0));
			})
	}, [props.categoryId, isMobile, props.initialLines]);

	return(
		<div className={'tw-my-16'}>
			<CategoryProductListHeader
				title={title!}
				link={link!} />
			<CategoryProductListRows
				blackFridayActive={props.blackFridayActive}
				orderId={props.orderId}
				preSlider={preSlider!}
				products={products}
				amountOfProductsToShow={amountOfProductsToShow} />
			<CategoryProductListLoadMoreButton
				products={products}
				amountOfProductsToShow={amountOfProductsToShow}
				setAmountOfProductsToShow={setAmountOfProductsToShow} />
		</div>
	);

}