import ReactDOM from "react-dom";
import {BundleEntry} from "./components/bundle-entry/BundleEntry";
import React from "react";
import {getBlLocaleFromString} from "./lib/BlLocale";
import {CategoryProductList} from "./components/category-product-list/CategoryProductList";


const targets = Array.from(document.getElementsByClassName('category-product-list'))
	.filter(el => el instanceof HTMLElement)
	.map(el => el as HTMLElement)
	.filter(el => el.dataset['categoryId']);

function fetchCategoryProductListAndDisplayList(targets: HTMLElement[]) {
	targets.forEach((el) => {
			const categoryId = el.dataset['categoryId']!;
			const rawLocaleCode = el?.dataset?.['localeCode'];
			const localeCode = getBlLocaleFromString(rawLocaleCode);
			const initialLines = el?.dataset?.['initialLines'] || '1';
			const blackFridayActive: boolean = el?.dataset?.['blackFridayActive'] === 'true';
			const rawOrderId = el?.dataset?.['orderId'];
			const orderId = rawOrderId != null ? parseInt(rawOrderId) : null;
			ReactDOM.render(
				<BundleEntry
					bundleName="category-product-list"
					locale={localeCode}
					prefetchTranslations={[
						'frontend.category.product.list.load.more',
						'category.goToAll'
					]}>
					<CategoryProductList
						blackFridayActive={blackFridayActive}
						orderId={orderId!}
						initialLines={Number(initialLines)}
						categoryId={categoryId} />
				</BundleEntry>
				, el)
		}
	);
}

fetchCategoryProductListAndDisplayList(targets);
