export interface CategoryProductListPreSliderImageProps {
	preSlider: string | undefined
}

export default function CategoryProductListPreSliderImage(props: CategoryProductListPreSliderImageProps) {

	return (
		<>
			{props.preSlider &&
				<img
					className={'tw-h-full'}
					src={props.preSlider}
					alt={'pre-slider'} />
			}
		</>
	)

}