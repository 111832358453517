import {redirectTo} from "../../lib/utility";
import {FormattedMessage} from "react-intl";

export interface CategoryProductListHeaderProps {
	title: string
	link: string
}

export default function CategoryProductListHeader(props: CategoryProductListHeaderProps) {

	return(
		<div className={`tw-flex tw-flex-1 tw-flex-row tw-font-gotham`}>
			<div className={`tw-flex tw-flex-1 tw-font-bold tw-text-4xl tw-mb-4`}>
				{props.title}
			</div>
			<div onClick={() => redirectTo(props.link!)} className={`hover:tw-underline tw-cursor-pointer`}>
				<FormattedMessage id={'category.goToAll'} />
			</div>
		</div>
	);

}