import {ProductCore} from "../../lib/model/product/ProductModel";
import {FormattedMessage} from "react-intl";

export interface CategoryProductListLoadMoreButtonProps {
	products: ProductCore[] | undefined
	amountOfProductsToShow: number
	setAmountOfProductsToShow: (a: number) => void
}

export default function CategoryProductListLoadMoreButton(props: CategoryProductListLoadMoreButtonProps) {

	return(
		<>
			{(props.products && props.amountOfProductsToShow < props.products?.length) &&
			<div className={`tw-flex tw-flex-1 tw-justify-center tw-mt-8`}>
				<div onClick={() => props.setAmountOfProductsToShow(props.amountOfProductsToShow + 4)}
					 className={`tw-bg-red tw-py-4 tw-px-8 tw-text-white tw-font-bold tw-rounded tw-cursor-pointer`}>
					<FormattedMessage
						id={'frontend.category.product.list.load.more'} />
				</div>
			</div>
			}
		</>
	);

}